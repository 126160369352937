<template>
  <v-card class="pa-4" flat>
    <v-slide-y-transition>
      <v-btn
        z-index="1000"
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        small
        color="primary"
        style="position: fixed; bottom: 20px; right: 20px; z-index: 999"
        @click="$vuetify.goTo(0)"
      >
        <v-icon>mdi-arrow-up-bold-circle</v-icon>
      </v-btn>
    </v-slide-y-transition>

    <!-- <div class="mb-5 font-weight-bold d-flex px-2">
      <v-spacer />
      <div>
        <span class="font-weight-bold subtitle-1">
          {{ $t("e_test.good_signal") }}
        </span>
        <v-badge color="lime accent-3" class="ml-5" />
      </div>
    </div> -->
    <v-row no-gutters>
      <v-card
        class="rounded-lg pa-4"
        :class="!darkmode || 'dark-mode'"
        flat
        width="100%"
      >
        <v-row no-gutters>
          <v-col cols="6" sm="4" class="divider pl-2">
            <div class="font-weight-light">{{ $t("app.subjects") }}</div>
            <div class="font-weight-bold">
              {{ question.header.lesson_name }}
            </div>
          </v-col>
          <v-col cols="6" sm="4" class="divider pl-2">
            <div class="font-weight-light">{{ $t("e_test.title") }}</div>
            <div class="font-weight-bold">{{ question.header.title }}</div>
          </v-col>
          <v-col cols="6" sm="4" class="pl-2">
            <div class="font-weight-light">
              {{ $t("e_test.remaining_duration") }}
            </div>
            <div class="font-weight-bold">
              {{ duration ? countDown : "00:00" }}
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-row>

    <Pagination
      :to="monitor.answered"
      :total="monitor.questions"
      :currentPage="pagination.page"
      :nextPage="question.body.next_page_url"
      :prevPage="question.body.prev_page_url"
      :handler="paginateLimit"
      id="paginate"
      buttonDone
      @done="dialogDone"
      @next="arrowPage"
      @back="arrowPage"
      @search="handleSearch"
    />
    <v-row
      id="question-wrapper"
      v-for="(item, index) in question.body.data"
      :key="item.id"
      no-gutters
      class="my-5"
    >
      <v-col cols="12" sm="1" class="align-start d-flex">
        <v-icon v-if="loadingAnswer[index]" class="mr-2">mdi-restore</v-icon>
        <v-icon v-else-if="isAnswer(item)" class="mr-2" color="primary">
          mdi-checkbox-marked-circle
        </v-icon>
        <v-icon v-else class="mr-2">mdi-minus-circle</v-icon>
        <span class="font-weight-bold">{{ item.number }}.</span>
      </v-col>
      <v-col cols="12" sm="11">
        <!-- start question -->
        <div class="mb-5 etest-container">
          <v-row>
            <v-col
              :sm="item.type === 'esay' ? 10 : 12"
              cols="12"
              class="align-center d-flex"
            >
              <span
                v-html="item.question"
                class="pt-2"
                @click="viewImage($event)"
              />
            </v-col>
            <v-col
              v-if="item.type === 'esay'"
              cols="12"
              sm="2"
              class="d-flex align-start justify-end"
            >
              <v-btn
                :disabled="!item.esay || loadingAnswer[index] ? true : false"
                :dark="!item.esay ? true : false"
                color="primary"
                class="mb-5 mt-3"
                outlined
                small
                depressed
                @click="postAnswer(item, index)"
                >{{ $t("app.save") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="item.question_file[0]">
            <v-col v-if="item.question_file.length === 1">
              <v-img
                v-if="validateImage(item.question_file[0])"
                :src="item.question_file[0]"
                style="cursor: pointer"
                max-height="200px"
                max-width="300px"
                @click="
                  {
                    urlFile = item.question_file[0];
                    viewFile = true;
                  }
                "
              />
              <v-chip
                v-else
                color="primary"
                @click="
                  {
                    urlFile = item.question_file[0];
                    viewFile = true;
                  }
                "
              >
                {{ item.question_file[0].replace(/^.*[\\/]/, "") }}
              </v-chip>
            </v-col>
            <div
              v-else
              v-for="file in item.question_file"
              :key="file"
              class="ma-2"
            >
              <v-chip
                @click="
                  {
                    urlFile = file;
                    viewFile = true;
                  }
                "
                color="primary"
              >
                {{ file.replace(/^.*[\\/]/, "") }}
              </v-chip>
            </div>
          </v-row>
        </div>
        <!-- end question -->

        <!-- start multiple choice -->
        <div v-if="item.type === 'pg'">
          <v-row>
            <v-radio-group
              v-model="item.student_answer"
              @change="postAnswer(item, index)"
              row
            >
              <v-col
                v-for="(itemAnswer, index) in item.pg"
                :key="index"
                cols="12"
                sm="6"
                class="mb-3"
              >
                <v-radio
                  :value="itemAnswer.code"
                  :disabled="loadingAnswer[index]"
                >
                  <template v-slot:label>
                    <div class="pt-3 d-flex flex-row">
                      <p class="mr-2">{{ itemAnswer.code }}.</p>
                      <span
                        v-html="itemAnswer.answer"
                        class="answer"
                        @click="viewImage($event)"
                      />
                    </div>
                  </template>
                </v-radio>
                <v-row v-if="itemAnswer.answer_file[0]">
                  <v-col v-if="itemAnswer.answer_file.length === 1">
                    <v-img
                      v-if="validateImage(itemAnswer.answer_file[0])"
                      :src="itemAnswer.answer_file[0]"
                      style="cursor: pointer"
                      height="auto"
                      width="100%"
                      @click="
                        {
                          urlFile = itemAnswer.answer_file[0];
                          viewFile = true;
                        }
                      "
                    />
                    <v-chip
                      v-else
                      color="primary"
                      @click="
                        {
                          urlFile = itemAnswer.answer_file[0];
                          viewFile = true;
                        }
                      "
                    >
                      {{ itemAnswer.answer_file[0].replace(/^.*[\\/]/, "") }}
                    </v-chip>
                  </v-col>
                  <div
                    v-else
                    v-for="file in itemAnswer.answer_file"
                    :key="file"
                    class="ma-2"
                  >
                    <v-chip
                      color="primary"
                      @click="
                        {
                          urlFile = file;
                          viewFile = true;
                        }
                      "
                    >
                      {{ file.replace(/^.*[\\/]/, "") }}
                    </v-chip>
                  </div>
                </v-row>
              </v-col>
            </v-radio-group>
          </v-row>
        </div>
        <!-- end multiple choice -->

        <!-- start esay -->
        <div v-else>
          <quill-editor
            ref="myQuillEditor"
            v-model="item.esay.answer"
            :options="editorOption"
            @blur="postAnswerEssay(item, index)"
          />
          <ButtonUpload
            :label="$t('ppdb.educational_history.upload_document')"
            :loading="loadingAnswer[index]"
            class="my-2"
            multiple
            depressed
            @getDocument="
              files => {
                item.esay.answer_file_temp = files;
                uploadAnswer(item, index);
              }
            "
          />
          <v-row no-gutters>
            <div
              v-for="(file, index) in item.esay.answer_file"
              :key="index"
              class="mr-1"
            >
              <v-chip
                v-if="isValidate(file)"
                color="primary"
                close
                @click="openLink(file)"
                @click:close="
                  removeFiles(item.esay.answer_file, file, item, index)
                "
              >
                {{ file.replace(/^.*[\\\/]/, "") }}
              </v-chip>
            </div>
          </v-row>
        </div>
        <!-- end esay -->
      </v-col>
    </v-row>
    <ModalConfirm
      :close="() => (this.doneConfirm = false)"
      :loading="loadingConfirm"
      :isOpen="doneConfirm"
      :save="doneEtest"
      :content="$t('e_test.msg_done_etest')"
      title="E-Test"
    />
    <ViewImage
      :viewFile="viewFile"
      :urlFile="urlFile"
      @close="viewFile = false"
    />
  </v-card>
</template>

<script>
import store from "@/store";
import {
  questionEtestStudent,
  answerEtestStudent,
  finishEtestStudent,
  monitorEtest
} from "@/api/admin/academic/eTest";
import {
  ImageExtend,
  QuillWatch,
  container
} from "@/plugins/quill-image-extend-module";
import moment from "moment/moment";
import { mapGetters } from "vuex";
import { getToken } from "@/utils/storage";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);
import { uploadVideo, uploadFile, deleteFile } from "@/api/admin/admin";
import { sliceAwsPath } from "@/utils/uploadS3";

import { quillEditor, Quill } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import katex from "katex";
import "katex/dist/katex.min.css";

Quill.register("modules/ImageExtend", ImageExtend);
import { isExternal } from "@/utils/validate";
import { isImageFile } from "@/utils/validate";

let typingTimer;
const doneTypingInterval = 750;

export default {
  created() {
    this.getQuestion();
    window.katex = katex;
    window.addEventListener("beforeunload", this.eventListener);

    /** TO DISABLE SCREEN CAPTURE **/
    document.addEventListener("keyup", e => {
      if (e.key == "PrintScreen") {
        navigator.clipboard.writeText("");
        alert("Screenshots tidak diperbolehkan!");
      }
    });

    /** TO DISABLE PRINTS WHIT CTRL+P **/
    document.addEventListener("keydown", e => {
      if (e.ctrlKey && e.key == "p") {
        alert("Print atau export ke PDF tidak diperbolehkan!");
        e.cancelBubble = true;
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    });
  },
  components: {
    Pagination: () => import("../components/Pagination"),
    ModalConfirm: () => import("@/components/ModalConfirm"),
    ButtonUpload: () => import("@/components/ButtonUpload"),
    quillEditor,
    ViewImage: () => import("../components/ViewImage")
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.eventListener);
  },
  mounted() {
    this.interval();
  },
  computed: {
    countDown() {
      return this.duration.format("h:mm:ss");
    },
    ...mapGetters(["g_language"]),
    darkmode() {
      return store.getters.g_darkmode;
    }
  },
  data() {
    return {
      moduleFileName: "ETEST",
      editorOption: {
        modules: {
          toolbar: {
            container: container,
            handlers: {
              image: function() {
                QuillWatch.emit(this.quill.id);
              }
            }
          },
          ImageExtend: {
            loading: true,
            name: "file",
            action: process.env.VUE_APP_BASE_API + "question_bank/upload_file",
            headers: xhr => {
              xhr.setRequestHeader("Authorization", "Bearer " + getToken());
              xhr.setRequestHeader("x-lang", this.$store.getters.g_language);
              xhr.setRequestHeader("x-institution", window.id_school);
            },
            response: res => {
              return res.data;
            }
          }
        }
      },
      fab: false,
      doneConfirm: false,
      loadingConfirm: false,
      minute: null,
      seconds: null,
      duration: null,
      loadingAnswer: [],
      question: {
        header: {},
        body: { data: [] }
      },
      pagination: {
        limit: 10,
        page: 1,
        search: ""
      },
      urlFile: "",
      viewFile: false,
      monitor: {
        questions: 0,
        answered: 0
      }
    };
  },
  methods: {
    async monitorEtest() {
      try {
        const { data } = await monitorEtest({
          etest_class: this.$route.params.etestClass
        });
        if (data.code) {
          this.monitor = data.data;
        }
      } catch (error) {
        console.error("monitorEtest()\n", error);
      }
    },
    eventListener(event) {
      event.preventDefault();
      event.returnValue = "";
    },
    postAnswerEssay(item, index) {
      this.postAnswer(item, index);
    },
    viewImage(event) {
      if (event.target.src) {
        this.viewFile = true;
        this.urlFile = event.target.src;
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 150;
    },
    validateImage: name => isImageFile(name),
    isValidate: url => isExternal(url),
    openLink: url => window.open(url, "_blank"),
    isAnswer(item) {
      let result = false;

      switch (item.type) {
        case "pg":
          if (item.student_answer) result = true;
          break;

        case "esay":
          if (item.esay.answer_file.length > 1 || item.esay.answer)
            result = true;
          break;

        default:
          result = false;
          break;
      }

      return result;
    },
    async uploadAnswer(item, index) {
      let files = item.esay.answer_file_temp;
      let result = [];
      if (files.length > 0 && files.length <= 5) {
        this.loadingAnswer[index] = true;
        try {
          await Promise.all(
            files.map(async file => {
              let body = new FormData();
              let response;
              body.append("file", file);
              // still hardcode
              body.set("preset", "MEDIUM"); // LOW, MEDIUM and HIGH

              if (
                /\.(3g2|3gp|avi|flv|h264|m4v|mkv|mov|mp4|mpg|rm|swf|vob|wmv)$/i.test(
                  file.name
                )
              ) {
                body.set("path", "e-test/student/");
                body.set("module", this.moduleFileName);
                response = await uploadVideo(body);
              } else {
                body.set("path", "e-test/student");
                body.set("module", this.moduleFileName);
                response = await uploadFile(body);
              }

              if (response.data.code) {
                result.push(response.data.data.path);
              } else {
                this.snackBar(false, response.data.message);
              }
            })
          );
          item.esay.answer_file.push(...result);
          this.postAnswer(item, index);
        } catch (error) {
          this.snackBar(false, this.$i18n.t("app.no_message_error"));
        } finally {
          this.loadingAnswer[index] = false;
        }
      }
    },
    async removeFiles(array, file, item, index) {
      try {
        const response = await deleteFile(
          sliceAwsPath(file),
          this.moduleFileName
        );
        if (response.data.code) {
          array.splice(array.indexOf(file), 1);
          this.postAnswer(item, index);
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, this.$i18n.t("app.no_message_error"));
      }
    },
    dialogDone() {
      this.doneConfirm = true;
    },
    interval() {
      let countDown = setInterval(() => {
        const endDate = moment.unix(
          parseInt(`${this.question.header.end_time}`.substring(0, 10))
        );
        const currentDate = moment();
        let hours;
        let minute;
        let seconds;

        if (this.duration) {
          const oneSeconds = moment.duration({ seconds: 1 });
          this.duration = moment
            .duration(this.duration, "minutes")
            .subtract(oneSeconds);
          minute = this.duration?.minutes();
          hours = this.duration?.hours();
          seconds = this.duration?.seconds();
          if (!this.$route.params.etestClass) clearInterval(countDown);
          // validate duration and current clock
          if (
            (hours === 0 && minute === 0 && seconds === 0) ||
            endDate.diff(currentDate, "minutes") < 0
          ) {
            clearInterval(countDown);
            this.doneEtest();
          }
        }
      }, 1000);
    },
    async postAnswer(item, index) {
      this.loadingAnswer.splice(index, 1, true);
      try {
        let body;
        switch (item.type) {
          case "pg":
            item.pg.map(d => {
              if (item.student_answer === d.code) {
                d.student_answer = true;
              } else {
                d.student_answer = false;
              }
            });
            body = { id: item.id, type: item.type, pg: item.pg };
            break;
          case "esay":
            body = {
              id: item.id,
              type: item.type,
              esay: {
                answer: item.esay.answer,
                answer_file: item.esay.answer_file
              }
            };
            break;
        }
        const response = await answerEtestStudent(body);
        if (!response.data.code) {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, this.$i18n.t("app.no_message_error"));
      }
      await this.getQuestion();
      this.loadingAnswer.splice(index, 1, false);
    },
    async doneEtest() {
      this.loadingConfirm = true;
      try {
        const response = await finishEtestStudent({
          etest_class: this.$route.params.etestClass
        });
        if (response.data.code) {
          this.snackBar(true, this.$i18n.t("e_test.finished_working_etest"));
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, this.$i18n.t("app.no_message_error"));
      }
      this.loadingConfirm = false;
      this.$router.push({ name: "EtestStudent", query: { tab: "1" } });
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    validateUrl: url =>
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        url
      ),
    async getQuestion() {
      this.$Progress.start();
      try {
        const response = await questionEtestStudent({
          etest_class: this.$route.params.etestClass,
          ...this.pagination
        });
        if (response.data.code) {
          // create object answer_file_temp for essay answers
          response.data.data.body.data.map(item => {
            if (item.type === "esay") {
              if (
                item.esay.answer_file[0] != "" &&
                this.validateUrl(item.esay.answer_file[0])
              ) {
                let path = [];
                item.esay.answer_file.map(data => path.push({ name: data }));
                item.esay.answer_file_temp = path;
              } else {
                item.esay.answer_file_temp = [];
              }
            }
          });

          const data = response.data.data;
          if (!this.duration)
            this.duration = moment.duration(
              data.header.duration_remaining,
              "minutes"
            );
          this.question = data;

          // set loading per question
          if (
            !this.loadingAnswer.length ||
            this.question.body.data.length != this.loadingAnswer.length
          ) {
            this.loadingAnswer = [];
            let n = 0;
            while (n < this.question.body.data.length) {
              this.loadingAnswer.push(false);
              n++;
            }
          }
          this.monitorEtest();
        } else {
          this.snackBar(false, response.data.message);
          this.$router.push({ name: "EtestStudent", query: { tab: "1" } });
        }
      } catch (error) {
        this.snackBar(false, this.$i18n.t("app.no_message_error"));
      } finally {
        this.$Progress.finish();
      }
    },
    paginateLimit(page, limit) {
      this.pagination.page = page;
      this.pagination.limit = limit;
      this.getQuestion();
    },
    handleSearch(value) {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.pagination.search = value;
        this.getQuestion();
      }, doneTypingInterval);
    },
    arrowPage(page) {
      this.question.body.data = [];
      this.pagination.page = page;
      this.getQuestion();
    }
  }
};
</script>

<style lang="scss">
.etest-container {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#paginate {
  position: sticky;
  top: 63px;
  z-index: 2;
}
#question-wrapper {
  width: 100%;
  img {
    width: 100%;
    border-radius: 15px;
  }
}
</style>
